<template>

  <div class="tab-content col-lg-10 col-md-10">
    <div class="iq-card-body p-0">
      <div class="row">
        <!-- b-statements__aside -->
        <LeftMenu :menuId="selectedId"></LeftMenu>
        <div class="col-lg-9 col-md-9">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{$t('label.income')}}</h4>
            </template>
            <template v-slot:body>
              <div class="allow-scroall" id="scrollbar-style-1">
                <table class="b-table m-responsive m-earnings">
                  <thead>
                    <tr>
                      <th>{{$t('table.dateTime')}}</th>
                      <th>{{$t('table.ammount')}}</th>
                      <th>{{$t('table.cost')}}</th>
                      <th>{{$t('table.income')}}</th>
                      <th>{{$t('table.desc')}}</th>
                      <th>{{$t('table.state')}}</th>
                    </tr>
                  </thead>
                  <tbody class="table-c" v-infinite-scroll="getRows">
                    <tr v-for="(item, index) in list" :key="index">
                      <td class="b-table__date">
                        <span class="b-table__date__date">
                          <span title=""> {{item.created_date}} </span>
                        </span>
                        <span class="b-table__date__time">
                          <h6 title="">{{item.created_time}}</h6>
                        </span>
                      </td>
                      <td :data-title="$t('table.ammount')" class="b-table__amount">
                        <span>{{item.order_amount}}</span>
                      </td>
                      <td :data-title="$t('table.cost')" class="b-table__fee">
                        <span>{{item.platform_fee}}</span>
                      </td>
                      <td :data-title="$t('table.income')" class="b-table__net">
                        <strong><span>{{item.creator_fee}}</span></strong>
                      </td>
                      <td class="b-table__desc">
                        <!-- <span>Subscription from
                            <a href="/u185505048">KrisChangCH</a>
                          </span> -->
                        <span>{{item.order_desc}}</span>
                        <span class="b-table__status-tip">{{item.creator_fee_status_name}}</span>
                      </td>
                      <td class="b-table__status">
                        <span v-if="item.creator_fee_status === 1">
                          <img style="width: 15px;" src="@/assets/images/loading.gif" alt="" />
                        </span>
                        <span v-if="item.creator_fee_status === 2">
                          <span class="font-size-20 text-success">
                            <i class="fa fa-check"></i>
                          </span>
                        </span>
                        <span v-if="item.creator_fee_status === 3">
                          <span class="font-size-20 text-danger">
                            <i class="fa fa-times"></i> {{$t('label.reject')}}
                          </span>
                        </span>
                      </td>
                    </tr>
                    <tr v-if="!list.length" class="table-emtpy-text">
                      <td colspan="6" class="text-center">{{ $t('此列表為空') }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </iq-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftMenu from '../User/Components/LeftMenu'
import { mapGetters } from 'vuex'
import { commomData } from '@/utils/commonVmodel'
import { getEarnings } from '@/api/balance'

export default {
  name: 'EarningList',
  props: ['menuId'],
  components: {
    LeftMenu
  },
  data() {
    return {
      primaryKey: 'created_at',
      commomData: commomData(),
      selectedId: 1,
      list: []
    }
  },
  methods: {
    async getRows() {
      if (this.commomData.noResult) {
        return false
      }
      this.commomData.listLoader = true
      this.commomData.condition.type = 'earnings'
      this.commomData.condition.order_by = this.primaryKey
      await getEarnings(this.commomData.condition)
        .then(res => {
          if (this.commomData.condition.page === 1) {
            this.list = res.data
            this.commomData.condition.page = 2
            this.commomData.listLoader = false
            if (res.total === 0) {
              this.commomData.noResult = true
            }
          } else if (res.total > this.list.length) {
            this.list.push(...res.data)
            this.commomData.condition.page = this.commomData.condition.page + 1
            this.commomData.listLoader = false
          } else if (res.total === this.list.length) {
            this.commomData.noResult = true
            this.commomData.listLoader = false
          }
        })
        .catch(err => {
          console.log(err)
          this.commomData.btnLoader = false
          this.$swal.mixin().fire({
            icon: 'error',
            title: 'err.response.data.message'
          })
        })
    }
  },
  computed: {
    ...mapGetters({
      user: 'User/userState',
      token: 'User/tokenState'
    })
  },
  created() {
    this.getRows()
  }
}
</script>
